// (function (){
//   var rep = /.*\?.*/,
//       links = document.getElementsByTagName('link'),
//       scripts = document.getElementsByTagName('script'),
//       process_scripts = false;
//   for (var i=0;i<links.length;i++){
//     var link = links[i],
//         href = link.href;
//     if(rep.test(href)){
//       link.href = href+'&'+Date.now();
//     }
//     else{
//       link.href = href+'?'+Date.now();
//     }

//   }
//   if(process_scripts){
//     for (var i=0;i<scripts.length;i++){
//       var script = scripts[i],
//           src = script.src;
//       if(rep.test(src)){
//         script.src = src+'&'+Date.now();
//       }
//       else{
//         script.src = src+'?'+Date.now();
//       }

//     }
//   }
//   // console.log("cache  cleaned");
// })();
